<template>
  <v-speed-dial v-model="dialShare" direction="left" open-on-hover absolute>
    <template v-slot:activator>
      <v-btn fab bottom x-small color="primary">
        <v-icon v-if="dialShare">mdi-close</v-icon>
        <v-icon v-else>mdi-share-variant</v-icon>
      </v-btn>
    </template>
    <v-btn dark fab bottom color="black" small @click="downloadQRPng(pageUrl)">
      <v-icon>mdi-qrcode</v-icon>
    </v-btn>

    <v-btn dark fab bottom color="blue" small :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrlEncoded}`"
      target="_blank">
      <v-icon>mdi-facebook</v-icon>
    </v-btn>
    <v-btn dark fab bottom color="green" small :href="`https://api.whatsapp.com/send?text=${name}.%20${pageUrlEncoded}`"
      target="_blank">
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
    <v-btn dark fab bottom color="tertiary" small :href="`mailto:?subject=;body=${name} ${pageUrlEncoded}`"
      target="_blank">
      <v-icon>mdi-email</v-icon>
    </v-btn>

    <v-btn v-show="$vuetify.breakpoint.smAndDown" dark fab bottom color="grey" small
      :href="`sms:?&body=${name} ${pageUrlEncoded}`" target="_blank">
      <v-icon>mdi-message</v-icon>
    </v-btn>

  </v-speed-dial>
</template>

<script>
import QRCode from 'qrcode';

export default {
  name: "ShareComponent",
  props: {
    pageUrl: { type: String, required: true },
    name: { type: String, required: true },
  },

  data: () => ({
    dialShare: false,
  }),

  computed: {
    pageUrlEncoded() {
      return encodeURIComponent(this.pageUrl);
    }
  },

  methods: {
    downloadQRPng(url) {
      QRCode.toDataURL(url, { width: 300 }, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }
        const link = document.createElement('a');
        link.href = url;
        link.download = 'qrcode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }
};
</script>
